<template>
  <div class="app-container">

    <el-row  type="flex" justify="end">
      <el-button-group>
        <el-button
          @click.stop.prevent="handleCreate"
          type="primary"
          icon="el-icon-circle-plus"
          size="mini"
          >Agregar</el-button
        >

      </el-button-group>
    </el-row>

    <el-table
      v-loading="loading"
      :data="list"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      style="width: 100%"
      size="mini"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column label="Nombre" >
        <template slot-scope="scope" align="center">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column label="Correo electrónico" >
        <template slot-scope="scope" align="center">
          {{ scope.row.email }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="Rol" >
        <div slot-scope="scope" v-if="scope.row.role=== undefined">
          Administrator
        </div>
        <div slot-scope="scope" v-else>
          {{ scope.row.role }}
        </div>
      </el-table-column> -->
      <el-table-column fixed="right" label="Opciones">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
            >Editar</el-button
          >
          <template v-if="name != scope.row.name">
            <el-button
              size="mini"
              type="danger"
              :loading="loadingDelete"
              @click.native.prevent="handleDelete(scope.$index, scope.row)"
              >Eliminar</el-button
            >
          </template>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getAdminList, destroyAdmin } from '@/api/admin'

export default {
  filters: {
    statusColor (status) {
      const statusMap = {
        published: 'success',
        draft: 'gray',
        deleted: 'danger'
      }
      return statusMap[status]
    }
  },
  data () {
    return {
      list: null,
      schema: [],
      loading: true,
      loadingDelete: false
    }
  },
  computed: {
    ...mapGetters([
      'name'
    ])
  },
  created () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.loading = true
      await getAdminList().then((response) => {
        this.list = response.data
      }).catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleCreate () {
      this.$router.push({ name: 'admin.create' })
    },
    handleEdit (index, row) {
      this.$router.push({ name: 'admin.edit', params: { _id: row._id } })
    },
    handleDelete (index, row) {
      this.loadingDelete = true
      this.$confirm(
        'This will permanently delete. Continue?',
        'Warning',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      )
        .then(async () => {
          await destroyAdmin(row._id)
            .then((response) => {
              // let res = response.data
              this.$message({
                showClose: true,
                message: 'Done!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
            .finally(() => (this.loadingDelete = false))
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          })
          this.loadingDelete = false
        })
    },
    clearFilter () {
      this.$refs.filterTable.clearFilter()
    }
  }
}
</script>
